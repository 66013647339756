.fileManager .fileDetails .folderIcon {
    width: 40px;
    height: 33px;
    margin-bottom: 20px;
}

.fileManager .fileDetails .addFolderIcon {
    width: 70px;
    height: 33px;
    margin-bottom: 20px;
}

.fileManager .fileDetails .folderName {
    position: relative;
    text-align: center;
    background: transparent;
    border-radius: 0px;
    cursor: pointer;
    height: 30px !important;
    border: none !important;
    outline: none;
}

.fileManager .filesList {
    padding-left: 20px;
    padding-right: 20px;
}

.fileManager .filesList .row {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.fileManager .filesList .fileslistNavBar {
    padding-top: 30px;
    padding-right: 25px;
    margin-bottom: 40px;
}

.fileManager .filesList .uploadNewFiles {
    width: 200px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272d3b33;
    border-radius: 10px;
    position: relative;
    float: right;
    margin-right: 20px;
    cursor: pointer;
}

.fileManager .filesList .uploadNewFiles img {
    width: 26px;
    height: 26px;
    position: relative;
    top: 12px;
    left: 20px;
}

.fileManager .filesList .uploadNewFiles button {
    height: 22px;
    text-align: left;
    font: normal normal bold 15px/22px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    background: none;
    outline: none;
    border: none;
    position: relative;
    top: 14px;
    left: 25px;
}

.fileManager .expInfo .statusDetails span {
    color: #87C748 !important;
}

.fileManager .filesList .backbutton {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    position: relative;
    cursor: pointer;
    float: left;
    top: 15%;
}

.fileManager .filesList .backbutton span {
    padding-right: 15px;
}

.fileManager .filesList .backbutton img {
    width: 8px;
    height: 13px;
}

.fileManager .filesList .rdt_TableHeader {
    display: none !important;
}

.fileManager .filesList .filesListTable .rdt_Table {
    max-height: 525px;
    height: 525px;
    overflow: auto;
}

.fileManager .filesList .filesListTable .rdt_Table .view {
    /* width: 150px; */
    background-color: #B7B8BA;
    text-align: left;
    font: normal normal normal 14px/19px Lato;
    letter-spacing: 0px;
    padding: 3px 16px 3px 16px;
    color: #354052;
    border: none;
    border-radius: 3px;
    outline: none;
    margin-right: 42px;
}

.fileManager .ActivityLogTitle {
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    width: 100%;
}

.fileManager .actionDiv {
    position: relative;
    border-bottom: solid 4px #FFA101;
    left: 1.5%;
    width: 84px;
    height: 4px;
}

.fileManager .filesList .filesListTable .rdt_Table .preview {
    /* width: 150px; */
    background-color: #B7B8BA;
    text-align: left;
    font: normal normal normal 14px/19px Lato;
    letter-spacing: 0px;
    padding: 3px 16px 3px 16px;
    color: #354052;
    border: none;
    border-radius: 3px;
    outline: none;
    margin-right: 20px;
}

.fileManager .filesList .filesListTable .rdt_Table .osIcon img {
    width: 35px;
    margin-right: 10px;
    cursor: pointer;
}

.fileManager .filesList .filesListTable .rdt_Table .download {
    /* width: 150px; */
    background-color: #322A7D;
    text-align: left;
    font: normal normal normal 14px/19px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 3px 16px 3px 16px;
    border-radius: 3px;
}

.fileManager .whiteBacground {
    background-color: #FFFFFF;
    max-height: 700px;
    height: 700px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 10px;
    padding-top: 40px;
}

.fileManager .fileDetails {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
    height: 115px;
    padding: 2%;
}

.fileManager .fileDetails input {
    background: transparent;
    border-radius: 10px;
    cursor: pointer;
    height: 115px;
    padding: 2%;
}

.fileManager .fileDetails:hover {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 10px;
    cursor: pointer;
    height: 115px;
    padding: 2%;
}

.fileManager .searchDiv {
    width: 25%;
    height: 49px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 100px;
    float: right;
    padding-left: 20px;
    padding-top: 2px;
}

.fileManager .selectdiv {
    position: relative;
}

.fileManager select::-ms-expand {
    display: none;
}

.fileManager .selectdiv:after {
    content: url(../images/arrow-right.png);
    top: 0%;
    right: 5%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.fileManager .selectdiv select {
    appearance: none;
    max-width: 320px;
    padding: 0px 14px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    -ms-word-break: normal;
    word-break: normal;
}

.fileManager .companyDetails span:first-child {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.fileManager .companyDetails span:first-child select option {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.fileManager .companyDetails span:first-child select {
    width: 35%;
    height: 49px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    margin-left: 15px;
    outline: none;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
    padding: 0px 14px;
}

.fileManager .companyDetails span:nth-child(2) img {
    position: relative;
    width: 26px;
    height: 26px;
    top: 10px;
    left: 30px;
}

.fileManager .searchDiv i {
    width: 24px;
    height: 24px;
    position: relative;
    top: -6px;
}

.fileManager .searchDiv span {
    font-size: 30px;
    color: lightgray;
    position: relative;
    top: -4px;
}

.fileManager .searchDiv input {
    width: 85%;
    border: none;
    outline: none;
    background: transparent;
    padding-left: 18px;
    position: relative;
    top: -6px;
}

.uploadFiles {
    height: 100%;
    padding: 8%;
    padding-top: 10%;
}

.uploadFiles .loginTitle {
    width: 100%;
    height: auto;
    word-break: break-word;
}

.uploadFiles .loginsubTitle {
    margin-top: 10px;
    margin-bottom: 10px !important;
    word-break: break-word;
}

.uploadFiles .chooseFilesText {
    color: #87C748;
}

.uploadFiles #FileUpload1 {
    /* display: none; */
}

.uploadFiles #uploadFilesDiv input {
    border: none !important;
    outline: none !important;
    border-bottom: 2px solid lightgrey !important;
    margin-top: 10px;
}

.uploadFiles #uploadFilesDiv img {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.uploadFiles #uploadFilesDiv .previewDiv {
    height: 80px;
    width: 80px;
    padding: 0px;
}

.uploadFiles .previewDiv p {
    position: absolute;
    right: 0;
    font-weight: 800;
    font-size: 12px;
    color: #87c748;
    cursor: pointer;
}

.uploadFiles .CancelRequestButton {
    margin-bottom: 45px;
    height: 33px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    font: normal normal normal 15px/23px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.uploadFiles #uploadFilesDiv #submitFilesbtn {
    width: 100%;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}