@media screen and (max-width: 1365px) {
    /*  Media queries Css for Min Width 1366 and Max 1600px */

    .App {
        display: none;
    }

    .Mobile {
        display: block;
    }
}

@media screen and (min-width: 1366px) and (max-width:1599px) {
    .App {
        display: block;
    }

    .Mobile {
        display: none;
    }

    .mainDashboard .quickStats .title {
        min-height: 40px;
    }

    .LicenseDashboard .licensesDiv .licenceKeyDiv {
        font: normal normal normal 11px/17px Lato !important;
        margin-right: 20px;
        min-width: 140px;
    }

    .LicenseDashboard .licensesDiv .infoDetails {
        font-size: 12px !important;
    }

    .LicenseDashboard .ecDzaP {
        padding-left: 35px;
        padding-right: 35px;
    }

    .LicenseDashboard .licensesDiv {
        height: 450px;
        min-height: 460px;
        max-height: 470px;
    }

    .fileManager .filesList .filesListTable .rdt_Table .view,
    .fileManager .filesList .filesListTable .rdt_Table .preview {
        font: normal normal normal 12px/19px Lato;
        padding: 1px 10px 3px 10px;
        margin-right: 8px;
        display: inline-block;
        padding: 3px 2px !important;
    }

    .fileManager .filesList .filesListTable .rdt_Table .download {
        font: normal normal normal 12px/19px Lato;
        padding: 1px 10px 3px 10px;
        border-radius: 3px;
        display: inline-block;
        padding: 3px 2px !important;
    }

    .fileManager .filesList .filesListTable .rdt_Table {
        max-height: 350px;
        height: 350px;
    }

    .SettingsDashboard .settingsManagementDiv {
        height: 568px;
        max-height: 568px;
    }

    .userRegistration .userlistSublabel {
        margin-top: 15% !important;
    }

    .settingsUsersList .userlistSublabel {
        height: 20px;
        margin-top: 20% !important;
    }

    .settingsUsersList .addUserButtion img {
        width: 20px;
        height: 20px;
    }

    .settingsUsersList .adduserText {
        font: normal normal bold 13px/22px Montserrat;
    }

    .deviceList .searchDiv input {
        font-size: 18px !important;
    }

    .buyLicenses .loginTitle {
        height: auto;
    }

    .addExperience .loginTitle {
        height: auto;
    }

    .licenseDetails .loginTitle {
        height: auto;
    }

    .licenseDetails .licenseKeyDetails .keyIcon img {
        width: 40px;
        height: 40px;
    }

    .licenseDetails .licenseKeyDetails .keyDetails .key {
        padding: 3px 0px 11px 0px !important;
    }

    .licenseDetails .licenseKeyDetails .keyDetails .copyImg {
        width: 16px;
        height: 16px;
        margin-left: 7px;
    }

    .licenseDetails .keyInfo .keyname {
        height: auto;
        margin-bottom: 7%;
    }

    .licenseDetails .keyInfo .keyvalue {
        height: auto;
    }

    .deviceDetails .licenseKeyDetails .keyIcon img {
        width: 40px;
        height: 40px;
    }

    .deviceDetails .licenseKeyDetails .keyDetails {
        padding: 9px;
    }

    .deviceDetails .licenseKeyDetails .keyDetails .key .deviceName {
        width: 62%;
        font: normal normal bold 15px/21px Raleway;
    }

    .deviceDetails .keydetailsDiv .keyname {
        height: auto;
        margin-bottom: 7%;
        word-break: break-all;
    }

    .deviceDetails .keydetailsDiv .keyvalue {
        height: auto;
    }

    .addExperience .loginTitle {
        height: auto;
    }

    .addExperience .addExpSubTitle {
        margin-top: 20px;
    }

    .buyLicenses .form-check-label {
        font-size: 14px;
    }

    .LicenseDashboard .licensesDiv .key {
        width: 60% !important;
    }

    .LicenseDashboard .licensesDiv .green,
    .LicenseDashboard .licensesDiv .red {
        margin-left: 0px !important;
    }

    .LicenseDashboard .licensesDiv .copyImg,
    .LicenseDashboard .licensesDiv .keySeperator {
        position: relative;
        top: 8px
    }
}

@media screen and (min-width: 1600px) and (max-width:1919px) {
    .App {
        display: block;
    }

    .Mobile {
        display: none;
    }

    .mainDashboard .quickStats .title {
        min-height: 40px;
    }

    .LicenseDashboard .licensesDiv .licenceKeyDiv {
        font: normal normal normal 11px/17px Lato !important;
    }

    .LicenseDashboard .licensesDiv .infoDetails {
        font-size: 12px !important;
    }

    .LicenseDashboard .ecDzaP {
        padding-left: 15px;
        padding-right: 15px;
    }

    .LicenseDashboard .licensesDiv {
        margin-top: 45px;
        text-align: left;
        font: normal normal normal 18px/19px Lato !important;
        letter-spacing: 0px;
        color: #354052;
        height: 550px;
        min-height: 560px;
        max-height: 570px;
        overflow-y: auto;
    }

    .LicenseDashboard .licensesDiv {
        height: 550px;
        min-height: 560px;
        max-height: 570px;
    }

    .fileManager .filesList .filesListTable .rdt_Table {
        max-height: 450px;
        height: 450px;
    }

    .SettingsDashboard .settingsManagementDiv {
        height: 700px;
        max-height: 700px;
    }

    .userRegistration .userlistSublabel {
        margin-top: 15% !important;
    }

    .fileManager .filesList .filesListTable .rdt_Table .download {
        padding: 3px 7px !important;
    }

    .fileManager .filesList .filesListTable .rdt_Table .view {
        padding: 3px 7px !important;
    }

    .fileManager .filesList .filesListTable .rdt_Table .preview {
        padding: 3px 7px !important;
    }

    .licenseDetails .licenseKeyDetails .keyIcon img {
        width: 40px;
        height: 40px;
    }

    .licenseDetails .licenseKeyDetails .keyDetails .key {
        padding: 3px 0px 11px 0px !important;
    }

    .licenseDetails .licenseKeyDetails .keyDetails .copyImg {
        width: 16px;
        height: 16px;
        margin-left: 10px;
    }

    .licenseDetails .keyInfo .keyname {
        height: auto;
        margin-bottom: 7%;
    }

    .licenseDetails .keyInfo .keyvalue {
        height: auto;
    }

    .deviceDetails .licenseKeyDetails .keyIcon img {
        width: 40px;
        height: 40px;
    }

    .deviceDetails .licenseKeyDetails .keyDetails {
        padding: 9px;
    }

    .deviceDetails .licenseKeyDetails .keyDetails .key .deviceName {
        width: 62%;
        font: normal normal bold 15px/21px Raleway;
    }

    .deviceDetails .keydetailsDiv .keyname {
        height: auto;
        margin-bottom: 7%;
        word-break: break-all;
    }

    .deviceDetails .keydetailsDiv .keyvalue {
        height: auto;
    }

    .addExperience .loginTitle {
        height: auto;
    }

    .addExperience .addExpSubTitle {
        margin-top: 20px;
    }

    .buyLicenses .form-check-label {
        font-size: 16px;
    }

    .LicenseDashboard .licensesDiv .key {
        width: 64% !important;
    }

    .LicenseDashboard .licensesDiv .green,
    .LicenseDashboard .licensesDiv .red {
        margin-left: 8px !important;
    }
}

@media screen and (min-width: 1920px) {
    .App {
        display: block;
    }

    .Mobile {
        display: none;
    }
}