.ExperienceDashboard .ActivityLogTitle {
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    width: 100%;
}

.ExperienceDashboard .companyDetails {
    padding: 6px;
    padding-top: 30px;
    width: 50% !important;
}

.ExperienceDashboard .actionDiv {
    position: relative;
    border-bottom: solid 4px #FFA101;
    left: 1.5%;
    width: 84px;
    height: 4px;
}

.ExperienceDashboard .expSearchBox {
    width: 55%;
    height: 50px;
    border: none;
    border: 1px solid grey;
    font-size: 15px;
    outline: none;
    background: transparent;
    border-radius: 20px;
    padding-left: 20px;
}

.ExperienceDashboard .experienceManagementDiv {
    height: 680px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    /* overflow-y: auto; */
}

.ExperienceDashboard .experienceManagementDetails {
    height: 665px;
    overflow-y: auto;
    margin-top: 30px;
}

.ExperienceDashboard .experienceManagementDetails .kPXkKA {
    border: none !important;
}

.ExperienceDashboard .companyDetails span:first-child {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.ExperienceDashboard .companyDetails span:first-child select option {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.ExperienceDashboard .companyDetails span:first-child select {
    width: 435px;
    height: 49px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    margin-left: 15px;
    outline: none;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.ExperienceDashboard .companyDetails span:nth-child(2) {
    width: 258px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
    float: right;
    cursor: pointer;
}

.ExperienceDashboard .companyDetails span:nth-child(2) img {
    position: relative;
    width: 26px;
    height: 26px;
    top: 7px;
    left: 30px;
}

.ExperienceDashboard .companyDetails .addExperiennceText {
    display: inline-block;
    position: relative;
    height: 24px;
    text-align: left;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    top: 10px;
    left: 50px;
}

.ExperienceDashboard .experienceDetails:first-child {
    width: 95%;
    height: 89px;
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #272D3B33;
    border-radius: 20px;
    margin: 60px 43px 35px 43px !important;
    padding-top: 20px;
    padding-left: 28px;
}

.ExperienceDashboard .experienceDetails {
    width: 100%;
    background: white 0% 0% no-repeat padding-box;
    margin: 7px 0px 10px 10px;
    padding-top: 20px;
    padding-left: 28px;
    min-height: 577px;
    max-height: 577px;
    overflow-x: hidden;
    overflow-y: auto;
}

.ExperienceDashboard .experienceDetails img, .ExperienceDashboard .experienceDetails img {
    cursor: pointer;
}

.ExperienceDashboard .experienceDetails img {
    width: 25%;
    display: block;
    text-align: center;
    /* height: 28px !important; */
    margin-bottom: 0%;
}

.ExperienceDashboard .experienceDetails .settingsOptions {
    position: relative;
    top: 15px;
}

.ExperienceDashboard .experienceDetails .settingsOptions p {
    height: 16px;
    text-align: center;
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
}

.ExperienceDashboard .experienceDetails .settingsOptions p, .ExperienceDashboard .experienceDetails .settingsOptions div {
    display: inline-block;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.ExperienceDashboard .experienceDetails .experienceName {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    padding-top: 2%;
    padding-bottom: 7%;
}

.ExperienceDashboard .experienceDetails .experienceName .companyName {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
    opacity: 1;
    user-select: none;
    cursor: pointer;
}

.ExperienceDashboard .rdt_TableBody {
    padding-right: 2%;
}

.ExperienceDashboard .rdt_TableRow {
    padding: 10px;
    border-bottom: 1px solid lightgrey;
}

.ExperienceDashboard .experienceDetails .experienceName .divider {
    font-size: 50px;
    font-weight: 200;
    top: 10px;
    position: relative;
    margin: 0px 8px;
}

.ExperienceDashboard .selectdiv {
    position: relative;
    float: left;
}

.ExperienceDashboard select::-ms-expand {
    display: none;
}

.ExperienceDashboard .selectdiv:after {
    content: url(../images/arrow-right.png);
    top: 61%;
    right: 5%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.ExperienceDashboard .selectdiv select {
    appearance: none;
    max-width: 320px;
    padding: 0px 14px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    -ms-word-break: normal;
    word-break: normal;
}

/*  Add experience Screen  */

.addExperience {
    padding-top: 141px;
}

.addExperience .experienceNameTitle {
    text-align: left;
    font: normal normal normal 17px/18px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    text-transform: none;
}

.addExperience .experienceNameTitle span {
    font: normal normal bold 15px/18px Raleway;
}

.addExperience .loginTitle {
    width: 100%;
    height: 44px;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    text-transform: none;
}

.addExperience .loginSubTitle {
    height: 20px;
    text-align: left;
    font: normal normal BOLD 16px/24px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-top: 20px;
}

.addExperience .addExpSubTitle {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-bottom: 100px;
}

.addExperience form .expName::placeholder, .addExperience form select::placeholder {
    height: 21px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
}

.addExperience .selectdiv select {
    appearance: none;
    padding: 0px 0 10px 0px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    -ms-word-break: normal;
    word-break: normal;
}

.addExperience .selectdiv:after {
    content: url(../images/arrow-right.png);
    /* top: 61%; */
    right: 13%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.addExperience form .expName, .addExperience form select {
    width: 100%;
    border: none;
    outline: none;
    font: normal normal normal 15px/25px Source Sans Pro;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    margin-bottom: 45px;
}

.addExperience .uploadExperienceBtn {
    width: 229px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.addExperience .footerDivHR {
    position: absolute;
    bottom: 5%;
    left: 0%;
    border-top: 1px solid #707070;
    width: 100%;
}

.addExperience .footerDiv {
    position: absolute;
    left: 25%;
    bottom: 11px !important;
}

.addExperience .fileDiv input[type=file] {
    cursor: pointer;
    display: inline-block;
    position: relative !important;
    width: 95% !important;
    height: 34px !important;
    overflow: hidden !important;
}

.addExperience .fileDiv label {
    color: #87c748;
    font-size: 16px;
}

.addExperience .fileDiv input[type=file]:before {
    height: 21px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro !important;
    letter-spacing: 0px;
    color: #43425D;
    width: 95% !important;
    display: inline-block !important;
    font-size: 16px;
    line-height: 32px;
    /* content: 'Upload Experience File from Baetho' !important; */
    background: white;
    padding: 0 10px;
}

.addExperience .fileDiv input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
}

.addExperience .fileDiv {
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid lightgrey;
    position: relative !important;
    display: inline-block !important;
    margin-bottom: 20px;
}

.addExperience .CancelRequestButton {
    margin-bottom: 45px;
    height: 33px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    font: normal normal normal 15px/23px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.addExperience .fileDiv span {
    position: relative !important;
    top: 0 !important;
}

.addExperience .fileDiv span img {
    width: 14px;
    height: 17px;
}

.mediaManagement .ActivityLogTitle {
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #322A7D !important;
    width: 100%;
}

.mediaManagement .expInfo {
    padding: 10px 0px 0px 14px;
}

.mediaManagement .filesList {
    padding: 10px 14px 0px 10px;
}

.mediaManagement .whiteBacground {
    background-color: #FFFFFF;
    height: 79vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
}

.mediaManagement .fileIcons label {
    padding-left: 35px;
    font: normal normal 900 16px/19px Lato;
    letter-spacing: 0px;
    color: #11141A;
}

.mediaManagement .fileIcons label img {
    width: 23px;
    height: 31px;
}

.mediaManagement .expInfo .companyTitle {
    width: 71px;
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    padding-top: 44px;
    padding-left: 35px;
}

.mediaManagement .expInfo .companyName {
    width: 100%;
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
    padding-top: 7px;
    padding-left: 35px;
}

.mediaManagement .expInfo .experienceTitle {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    padding-top: 55px;
    padding-left: 35px;
}

.mediaManagement .expInfo .experienceName {
    /* height: 22px; */
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
    padding-top: 7px;
    padding-left: 35px;
    word-break: break-all;
}

.mediaManagement .expInfo .statusDetails {
    height: 17px;
    text-align: left;
    font: normal normal bold 14px/19px Lato;
    letter-spacing: 0px;
    padding-left: 35px;
    padding-top: 55px;
    margin-bottom: 55px;
}

.mediaManagement .expInfo .statusDetails span {
    color: #87C748 !important;
}

.mediaManagement .filesList .backbutton {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    position: relative;
    top: 19%;
    cursor: pointer;
}

.mediaManagement .filesList .backbutton span {
    padding-right: 15px;
}

.mediaManagement .filesList .backbutton img {
    width: 8px;
    height: 13px;
}

.mediaManagement .filesList .row {
    padding-left: 35px;
    padding-right: 35px;
}

.mediaManagement .filesList .fileslistNavBar {
    padding-top: 30px;
    padding-right: 25px;
    margin-bottom: 40px;
}

.mediaManagement .filesList .uploadNewFiles {
    width: 323px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
    position: relative;
    left: 28%;
}

.mediaManagement .filesList .uploadNewFiles img {
    width: 26px;
    height: 26px;
    position: relative;
    top: 12px;
    left: 51px;
}

.mediaManagement .filesList .uploadNewFiles button {
    height: 22px;
    text-align: left;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
    background: none;
    outline: none;
    border: none;
    position: relative;
    top: 14px;
    left: 84px;
}

.mediaManagement .filesList .fileDetails {
    height: 115px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 24px 20px 10px 31px;
}

.mediaManagement .filesList .fileDetails:hover {
    height: 115px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 24px 20px 10px 31px;
    cursor: pointer;
}

.mediaManagement .filesList .fileDetails .addFolderIcon {
    width: 64px;
    height: 30px;
    margin-bottom: 20px;
}

.mediaManagement .filesList .fileDetails .folderIcon {
    width: 34px;
    height: 30px;
    margin-bottom: 20px;
}

.mediaManagement .filesList .fileDetails p {
    height: 19px;
    text-align: center;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.mediaManagement .filesList .filesListDetials thead tr {
    border-bottom: 1px solid #707070;
}

.mediaManagement .filesList .table th {
    border-top: 0px !important;
}

.mediaManagement .filesList .filesListDetials th {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.mediaManagement .filesList .filesListDetials tbody {
    border-top: 1px solid #707070;
}

.mediaManagement .filesList .filesListDetials tbody .linkType {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.mediaManagement .filesList .filesListDetials tbody .location {
    height: 44px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border-radius: 100px;
    text-align: left;
    font: normal normal normal 14px/17px Lato;
    letter-spacing: 0px;
    color: #354052;
    padding: 14px 14px 14px 20px;
}

.mediaManagement .filesList .filesListDetials tbody .linkButtons {
    height: 22px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/22px Lato;
    letter-spacing: 0px;
    color: #BBC5D5;
    text-transform: uppercase;
    cursor: pointer;
}

.mediaManagement .filesList .filesListDetials tbody tr {
    padding-top: 20px;
}

.mediaManagement .table td, .mediaManagement .table th {
    vertical-align: middle;
}

/* Media Details Right Sidepanel CSS */

.mediaLinkDetails .backbutton img {
    width: 8px;
    height: 13px;
    margin-right: 10px;
}

.mediaLinkDetails .backbutton {
    height: 19px;
    text-align: left;
    font: normal normal normal 12px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    position: relative;
    top: 19%;
    cursor: pointer;
    margin-bottom: 35px;

}

.mediaLinkDetails .signin {
    padding-top: 40px !important;
}

.mediaLinkDetails .searchDiv {
    width: 100%;
    height: 59px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border-radius: 100px;
    padding-top: 9px;
    margin-bottom: 10px;
}

.mediaLinkDetails .searchDiv i {
    padding: 12px;
    font-size: 16px;
}

.mediaLinkDetails .searchDiv span {
    font-size: 22px;
    color: #BBC5D5;
    padding: 6px;
}

.mediaLinkDetails .searchDiv input {
    background: transparent;
    background-color: transparent;
    border: none;
    outline: none;
    height: 35px;
}

.mediaLinkDetails .title {
    height: 44px;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.mediaLinkDetails .subTitle {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.mediaLinkDetails .mediaContainer {
    min-height: 660px;
    height: 660px;
    max-height: 660px;
    overflow-x: hidden;
    overflow-y: auto;
}

.mediaLinkDetails .individualmediaLink .mediaIcon {
    margin-bottom: 20px;
}

.mediaLinkDetails .individualmediaLink .mediaIcon {
    width: 64px;
    height: 64px;
    border-radius: 15px;
}

.mediaLinkDetails .individualmediaLink {
    margin-bottom: 10px;
}

.mediaLinkDetails .individualmediaLink .title {
    height: auto;
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
    word-break: break-word;
}

.mediaLinkDetails .individualmediaLink .linkbutton {
    width: 88px;
    border: none;
    height: 20px;
    background: #FF8E8E 0% 0% no-repeat padding-box;
    border-radius: 9px;
    color: white;
    height: 15px;
    text-align: center;
    font: normal normal normal 12px/12px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-right: 10px;
}

.ExperienceDashboard .rdt_TableHeadRow, .ExperienceDashboard .rdt_TableHeader {
    display: none;
}