.marketPalceDashboard .headerContentSection {
    padding-left: 3%;
    padding-right: 3%;
}

.marketPalceDashboard .backDiv {
    cursor: pointer;
}

.marketPalceDashboard .headerContentSection img {
    transform: scale(0.85);
    padding-top: 5%;
    padding-bottom: 12%;
}

.marketPalceDashboard .backIcon, .marketPlaceUserInfo .backIcon {
    width: 9px;
    height: 12px;
    display: inline-block;
    position: relative;
    top: -1px;
}

.marketPalceDashboard .backBtnText, .marketPlaceUserInfo .backBtnText {
    display: inline-block;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-left: 10px;
}

.marketPalceDashboard .downloadBox {
    width: 100%;
    margin-top: 2%;
    min-height: 375px;
    background-color: transparent;
    text-align: center;
    max-height: 300px;
    overflow: hidden;
}

.downloadBox video {
    object-fit: cover;
    width: 100%;
    height: 375px;
    border-radius: 12px;
}

.dashboardHR {
    position: relative;
    width: 97%;
}

.marketPalceDashboard .downloadBox p {
    /* height: 36px;
    text-align: center;
    font: normal normal bold 30px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
    margin-top: 7%; */
    height: 36px;
    text-align: center;
    font: normal normal bold 30px/19px Lato;
    letter-spacing: 0px;
    color: white;
    margin-top: 10%;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 33%;
}

.marketPalceDashboard .downloadBox button {
    height: 59px;
    background: #87C748 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 278px;
    text-align: left;
    font: normal normal bold 16px/22px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-align: center;
    box-shadow: none;
    border: none;
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 41.5%;
}

.mostViewed .projDiv {
    width: 100px;
    height: 110px;
    padding: 6px 40px 6px 40px;
}

.mostViewed .projDiv p {
    margin: auto;
    text-align: center;
    height: 100px;
    position: relative;
    top: 35%;
}

.mostViewed .projDiv img {
    width: 100%;
    height: 100%;
    padding: 0px;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

.mostViewProjectsUser .projDiv img {
    width: 100%;
    height: 100%;
    padding: 0px;
    border-radius: 15px;
    box-shadow: 0px 3px 6px #00000029;
}

.mostViewed .projDiv .button, .mostViewProjectsUser .projDiv .button {
    display: none;
}

.mostViewed .projDiv:hover .button {
    display: inline-block;
    z-index: 9;
    color: white;
    position: absolute;
    background: #8FBB5C;
    opacity: 0.6;
    width: 57%;
    height: 88%;
    right: 22%;
    top: 6%;
    padding: 8px 10px;
    border-radius: 10px;
    text-align: center;
}

.mostViewed .projDiv:hover .button span {
    position: relative;
    top: 30%;
    right: 3%;
    opacity: 1;
}

.marketPalceDashboard .discoverProjectsDiv .projectsContent {
    min-height: 540px;
    max-height: 540px;
    height: 540px;
    overflow-x: hidden;
    overflow-y: auto;
}

.marketPalceDashboard .discoverProjectsDiv .projectsContent .individualProj .projectTitle {
    font-size: 12px;
}

.marketPalceDashboard .discoverProjectsDiv .projectsContent .individualProj .mainImg {
    height: 200px;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 5px;
    cursor: pointer;
}

.marketPalceDashboard .discoverProjectsDiv .projectsContent .col-3 {
    padding: 1% 2%;
    border-radius: 20px;
}

.marketPalceDashboard .discoverProjectsDiv .projectsContent .heartIconDiv, .marketPalceDashboard .discoverProjectsDiv .projectsContent .bookmarkIconDiv {
    float: right;
    margin-right: 5%;
    text-align: center;
}

.marketPalceDashboard .discoverProjectsDiv .projectsContent .heartIconDiv img, .marketPalceDashboard .discoverProjectsDiv .projectsContent .bookmarkIconDiv img, .peopleDetails .bookmarkIconDiv img, .peopleDetails .heartIconDiv img {
    height: 17px;
}

.marketPalceDashboard .discoverProjectsDiv .projectsContent .heartIconDiv span, .marketPalceDashboard .discoverProjectsDiv .projectsContent .bookmarkIconDiv span, .peopleDetails .bookmarkIconDiv span, .peopleDetails .heartIconDiv span {
    font-size: 10px;
    position: relative;
    top: -4px !important;
}

.tutorialsDashboard .videos {
    height: 218px;
    width: 100%;
}

.tutorialsDashboard .mainImg {
    position: relative;
    display: inline-block;
    /* left: 3px; */
    top: -2px;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    border-radius: 11px;
}

.tutorialsDashboard .videoOverlay {
    position: absolute;
    display: inline-block;
    left: 3px;
    top: -9px;
    opacity: 1;
    width: 98%;
    height: 45%;
}

.tutorialsDashboard .inforamtionDiv {
    color: #212529;
    font-size: 14px;
}

.tutorialsDashboard .tutorialsContentDiv {
    min-height: 550px;
    height: 550px;
    max-height: 550px;
    overflow: auto;
}

.uploadNewProject .uploadContainer {
    min-height: 540px;
    max-height: 540px;
    height: 540px;
    background: white;
    max-width: 100%;
}

.uploadNewProject .uploadContainer .uploadNewTitle {
    font: normal normal bold 30px/36px Lato;
    color: #354052;
}

.projectDetails .projectInformation {
    background: white;
    margin: 0% 0%;
    min-height: 545px;
    max-height: 545px;
    height: 545px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.projectDetails .projectInformation .projectTitleDetails {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    height: 120px;
    max-height: 130px;
}

.projectDetails .projectTitle {
    width: 100%;
    height: 47px;
    text-align: left;
    font: normal normal bold 30px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    word-break: break-word;
    padding: 40px 15px 10px 40px;
    margin: 0px;
}

.projectDetails .projectDesc {
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    padding: 35px 17px 10px 30px;
    margin: 0px;
    overflow-y: auto;
    height: 100px;
    max-height: 100px;
}

.projectDetails .mediaDiv {
    width: 100%;
    height: 400px;
    padding: 60px;
    display: inline-block;
}

.projectDetails .mediaDiv .projectImages {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.projectDetails #mediaDetails {
    max-height: 420px;
    overflow-y: auto;
}

.projectDetails #mediaDetails .commentBtn {
    width: 146px;
    position: relative;
    height: 24px;
    top: 34%;
    background: #322A7D 0% 0% no-repeat padding-box;
    border-radius: 9px;
    font: normal normal normal 15px/14px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: none;
}

.projectDetails #mediaDetails textarea::placeholder {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 35%;
}

.projectDetails #mediaDetails textarea {
    text-align: left;
    border: none;
    outline: none;
}

.projectDetails #mediaDetails .commentSection {
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    max-width: 100%;
    overflow: hidden;
}

.uploadContainer .col-2 img {
    cursor: pointer;
}

.uploadProjectSection input, .uploadProjectSection textarea {
    font: normal normal normal 20px/32px Raleway;
    width: 100%;
    box-shadow: 0px 2px 3px #00000029;
    border-radius: 12px;
    border: none;
    outline: none;
    height: 61px;
    padding-left: 20px;
    padding-right: 20px;
}

.uploadProjectSection textarea {
    height: 125px;
}

.uploadProjectSection2 {
    padding-left: 8% !important;
    padding-right: 8% !important;
}

.uploadProjectSection2 label {
    margin-top: 3% !important;
}

.uploadProjectSection2 .thumbnailDiv {
    width: 100%;
    height: 249px;
    box-shadow: 0px 2px 3px #00000029;
    border-radius: 12px;
}

.uploadProjectSection label {
    font: normal normal bold 20px/32px Raleway;
    width: 100%;
    text-align: left;
    color: #B7B8BA;
    /* margin-top: 20px; */
    /* border: none;
    border-bottom: 1px solid lightgray;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500; */
}

.uploadNewProject .nextBtn, .uploadNewProject .nextBtn:hover {
    position: relative;
    margin-top: 2%;
    width: 172px;
    height: 41px;
    background: #87C748 0% 0% no-repeat padding-box;
    outline: none;
    border-radius: 6px;
    font: normal normal bold 16px/22px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.marketPalceDashboard .peopleDetails .peopleDiv {
    min-height: 540px;
    height: 540px;
    width: 100%;
}

.marketPalceDashboard .peopleDetails .peopleDiv .userProficePic {
    height: 64px;
    width: 64px;
    border-radius: 5px;
}

.marketPalceDashboard .peopleDetails .peopleDiv .individualPerson {
    width: 100%;
    border-bottom: 1px solid lightgray;
}

.marketPalceDashboard .peopleDetails .peopleDiv .userInformationDiv {
    text-align: center;
    margin: auto;
}

.peopleDetails .userName {
    font: normal normal bold 18px/21px Raleway;
    color: #354052;
}

.peopleDetails .userDesc {
    font: normal normal normal 12px/14px Lato;
    color: #7F8FA4;
}

.peopleDetails .heartIconDiv, .peopleDetails .bookmarkIconDiv {
    margin-right: 5%;
    text-align: center;
}

.peopleDetails .bookmarkIconDiv img, .peopleDetails .heartIconDiv img {
    height: 15px;
}

.peopleDetails .followBtn {
    width: 102px;
    height: 24px;
    background: #322A7D 0% 0% no-repeat padding-box;
    border-radius: 9px;
    display: block;
    font: normal normal normal 13px/14px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    position: relative;
    left: 23%;
    border: none;
}

.peopleDetails .mostLikedProjects {
    width: 100px;
    height: 100px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    top: -16%;
    display: inline-block;
    margin-right: 4%;
    margin-left: 4%;
    cursor: pointer;
}

.peopleDetails .mostLikedProjects img {
    width: 100%;
    height: 100%;
}

.peopleDetails .peopleDetailsDiv {
    max-height: 540px;
    height: 540px;
    overflow-x: hidden;
    overflow-y: auto;
}

.marketPlaceUserInfo {
    padding: 0px 40px;
}

.marketPlaceUserInfo .notificationBtns {
    text-align: left;
    font: normal normal normal 13px/14px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    height: 24px;
    background: #87C748 0% 0% no-repeat padding-box;
    border-radius: 9px;
    border: none;
    outline: none;
    padding: 5px 12px;
    margin-right: 10px;
}

.marketPlaceUserInfo hr {
    width: 135%;
    position: relative;
    left: -20%;
}

.marketPlaceUserInfo .notificationBtnsActive {
    background: #322A7D 0% 0% no-repeat padding-box !important;
}

.marketPlaceUserInfo .marketUserDetails .followerstext {
    text-align: center;
}

.marketPlaceUserInfo .marketUserDetails .followerstext span {
    background-color: #322A7D;
    text-align: left;
    font: normal normal normal 15px/14px Lato;
    letter-spacing: 0px;
    color: white;
    border-radius: 20px;
    padding: 4px 16px;
}

.marketPlaceUserInfo .marketUserDetails .userProfilePic {
    position: relative;
    margin-top: 10%;
    min-width: 126px;
    min-height: 126px;
    max-width: 126px;
    max-height: 126px;
    box-shadow: 0px 2px 3px #00000029;
    border-radius: 10px;
}

.marketPlaceUserInfo .notificationDiv {
    width: 50px;
    height: 50px;
    background: lightgray;
    float: right;
    border-radius: 28px;
}

.marketPlaceUserInfo .notificationDiv img {
    width: 100%;
    height: 100%;
    transform: scale(0.45);
    cursor: pointer;
}

.marketPlaceUserInfo .marketUserDetails .userDesignation {
    font: normal normal normal 12px/15px Lato;
    width: 159px;
    word-break: break-word;
    position: relative;
    left: 30%;
    color: #7F8FA4;
}

.marketPlaceUserInfo .marketUserDetails .userName {
    color: #354052;
    font: normal normal bold 23px/27px Raleway;
}

.marketPlaceUserInfo .marketUserDetails .heartIconDiv {
    margin: 7px;
}

.marketPlaceUserInfo .marketUserDetails .heartIconDiv span {
    font: normal normal normal 12px/15px Lato;
    color: #7F8FA4;
    position: relative;
    top: -7px;
}

.marketPlaceUserInfo .mostViewProjectsUser .mostViewProjectsUserTitle {
    font: normal normal normal 14px/16px Lato;
    color: #7F8FA4;
}

.marketPlaceUserInfo .editProfileDiv {
    color: #7F8FA4;
    font: normal normal normal 10px /15px Lato;
    height: 18px;
    padding: 0px;
    width: 100%;
}

.marketPlaceUserInfo .editProfileDiv .editProfiletext {
    color: #7F8FA4;
    font: normal normal normal 10px/15px Lato;
    border: 1px solid lightgray;
    border-radius: 14px;
    width: 70px;
    margin: 0px;
    padding: 2px 8px;
    cursor: pointer;
}

.mostViewProjectsUser .projDiv {
    width: 100px;
    height: 100px;
    padding: 8px 19px;
}

.mostViewProjectsUser .projDiv img {
    cursor: pointer;
}

.marketPlaceUserInfo .mostViewProjectsUser {
    position: relative;
    margin-top: 43%;
    min-height: 230px;
    max-height: 230px;
}

.marketPlaceUserInfoDiscover .mostViewProjectsUser {
    margin-top: 58% !important;
}

.sideBtnsProjectDetails {
    max-width: 50px;
    width: 50px;
    margin-right: 7px;
    margin-left: 7px;
    text-align: center;
}

.sideBtnsProjectDetails .expTest {
    font: normal normal normal 9px/13px Lato;
    color: #707070;
    margin-bottom: 10px !important;
}

.sideBtnsProjectDetails .heartIconDiv1, .sideBtnsProjectDetails .bookmarkIconDiv1 {
    display: block;
}

.sideBtnsProjectDetails .heartIconDiv1 img, .sideBtnsProjectDetails .bookmarkIconDiv1 img {
    width: 40px;
    height: 40px;
}

.sideBtnsProjectDetails img {
    cursor: pointer;
}

.downloadComposerView {
    padding: 20% 12% 3% 12%;
}

.downloadComposerView .titleDiv .title {
    font: normal normal bold 37px/32px Raleway;
    color: #11141A;
}

.downloadComposerView .titleDiv .subTitle {
    font: normal normal normal 12px/15px Lato;
    color: #7F8FA4;
}

.downloadComposerView .uplaodProfilePic {
    font: normal normal normal 15px/20px Lato;
    color: #43425D;
}

.downloadComposerView input, .downloadComposerView textarea {
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid lightgray;
    color: #43425D;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    margin-bottom: 7%;
}

.downloadComposerView .nextBtn {
    background: #43425D;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 229px;
    height: 50px;
    margin-top: 5%;
}

.downloadComposerView #imgupload, .tutorials #imgupload {
    display: none;
}

.downloadComposerView label {
    cursor: pointer;
}

.downloadComposerView #preview, .tutorials #preview11 {
    overflow: hidden;
    min-width: 126px;
    min-height: 126px;
    max-width: 126px;
    max-height: 126px;
}

.downloadComposerView #preview img, .tutorials #preview11 img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 2px 3px #00000029;
}

.tutorials input {
    font: normal normal normal 15px/25px Source Sans Pro !important;
    color: #43425D !important;
}

.tutorials {
    margin-top: 20%;
}

.commentsSection {
    padding-left: 8% !important;
}

.commentsSection .commentName {
    color: #354052;
    font: normal normal bold 18px/21px Raleway;
}

.commentsSection .commentValue {
    color: #7F8FA4;
    font: normal normal normal 12px/15px Lato;
}

.profilePicComment {
    max-width: 64px !important;
    max-height: 64px;
    padding: 0px !important;
}

.profilePicComment img {
    width: 100%;
    height: 100%;
}

#mediaDetails .commentsText {
    font: normal normal normal 15px/15px Lato;
    color: #11141A;
}