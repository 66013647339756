.SettingsDashboard .ActivityLogTitle {
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    width: 100%;
}

.SettingsDashboard .actionDiv {
    position: relative;
    border-bottom: solid 4px #FFA101;
    left: 1.5%;
    width: 84px;
    height: 4px;
}

.SettingsDashboard hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.SettingsDashboard .settingsManagementDiv {
    background: #ffffff;
    padding: 40px 32px 40px 32px;
    height: 770px;
    max-height: 770px;
    overflow-y: auto;
}

.SettingsDashboard .settingsManagementDiv .sectionBox {
    /* width: 214px; */
    height: 167px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    margin-top: 60px;
    border-radius: 20px;
    padding: 44px 0px 44px 14px;
    border: 3px solid transparent;
    cursor: pointer;
}

.SettingsDashboard .settingsManagementDiv .activeBox, .SettingsDashboard .settingsManagementDiv .sectionBox:hover {
    border: 3px solid #87C748 !important;
    cursor: pointer;
}

.SettingsDashboard .settingsManagementDiv .sectionBox .settingType {
    height: 19px;
    text-align: left;
    font: normal normal normal 16px/19px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.SettingsDashboard .settingsManagementDiv .sectionBox .settingName {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.SettingsDashboard .settingsTitle {
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.SettingsDashboard .settingsManagementDiv .sectionBox .settingStatus {
    text-align: left;
    font: normal normal bold 14px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.SettingsDashboard .settingsManagementDiv .sectionBox .settingStatus span {
    height: 17px;
    text-align: left;
    font: normal normal bold 14px/19px Lato;
    letter-spacing: 0px;
    color: #87C748;
}

.settingsUsersList {
    padding-top: 0px !important;
    /* height: 100%; */
    padding: 40px;
}

.settingsUsersList .companyDetails {
    padding: 6px;
    padding-top: 60px;
    width: 100% !important;
    padding-left: 0px !important;
}

.settingsUsersList .userlistlabel {
    height: 44px;
    text-align: left;
    font: normal normal bold 32px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.settingsUsersList .userlistSublabel {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.settingsUsersList .addUserButtion img {
    width: 26px;
    height: 26px;
    /* background: #FF007C 0% 0% no-repeat padding-box; */
}

.settingsUsersList .adduserText {
    display: inline-block;
    position: relative;
    left: 10%;
    top: 4%;
    cursor: pointer;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #272D3B;
}

.settingsUsersList .addUserButtion {
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
    padding: 7px 7px 7px 0px;
}

.settingsUsersList .userTitle {
    margin-top: 35px;
    margin-bottom: 65px;
}

.settingsUsersList .selectdiv {
    position: relative;
}

.settingsUsersList select::-ms-expand {
    display: none;
}

.settingsUsersList .selectdiv:after {
    content: url(../images/arrow-right.png);
    top: 68%;
    right: 5%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.settingsUsersList .selectdiv select {
    appearance: none;
    max-width: 100%;
    padding: 0px 14px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    -ms-word-break: normal;
    word-break: normal;
}

.settingsUsersList .companyDetails p:first-child {
    height: 40px;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.settingsUsersList .companyDetails span:first-child {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.settingsUsersList .companyDetails span:first-child select option {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.settingsUsersList .companyDetails span:first-child select {
    width: 100%;
    height: 49px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    outline: none;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
    padding-left: 14px;
}

.settingsUsersList .companyDetails span:nth-child(2) {
    width: 258px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 10px;
    float: right;
    cursor: pointer;
}

.settingsUsersList .companyDetails span:nth-child(2) img {
    position: relative;
    width: 26px;
    height: 26px;
    top: 10px;
    left: 30px;
}

.settingsUsersList .userIcon {
    width: 48px;
    height: 53px;
}

.settingsUsersList .indvUsersDiv {
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
}

.settingsUsersList .indvUsers {
    border-bottom: 1px solid #707070;
    padding-bottom: 14px;
    padding-top: 10px;
}

.settingsUsersList .indvUsers .username {
    /* height: 30px; */
    text-align: left;
    font: normal normal bold 25px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    word-break: break-word;
    padding-left: 13px !important;
}

.settingsUsersList .indvUsers .userRole {
    height: 15px;
    text-align: left;
    font: normal normal normal 12px/18px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    word-break: break-word;
    padding-left: 13px !important;
}

.settingsUsersList .indvUsers .deleteUserBtn {
    width: 36px;
    height: 15px;
    text-align: center;
    text-decoration: underline;
    font: normal normal 900 12px/17px Lato;
    letter-spacing: 0px;
    color: #F35162;
    border: transparent;
    background: transparent;
}

.userRegistration .userTitle {
    margin-top: 155px;
    margin-bottom: 65px;
}

.settingsEmailConfig .userTitle {
    margin-top: 45px;
    margin-bottom: 65px;
}

.userRegistration .userlistlabel {
    height: 44px;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.userRegistration .userlistSublabel {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.userRegistration form input {
    padding-bottom: 10px;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #000;
    margin-bottom: 25px;
    height: 25px;
    text-align: left;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
}

.userRegistration {
    padding-right: 60px;
}

.userRegistration .css-2b097c-container {
    height: 50px;
    border: none;
    margin-bottom: 20px;
    outline: none !important;
    border-bottom: 2px solid #C3E6A1;
}

.userRegistration .css-yk16xz-control {
    height: 45px;
    outline: none !important;
    border: none;
}

.userRegistration .css-g1d714-ValueContainer {
    height: 45px;
    border: none;
    outline: none !important;
    padding: 0px !important;
}

.userRegistration .css-1okebmr-indicatorSeparator {
    background-color: transparent;
    outline: none !important;
}

.userRegistration .css-1pahdxg-control:hover {
    box-shadow: transparent !important;
    border-color: transparent !important;
}

.userRegistration .css-1pahdxg-control {
    border-color: transparent !important;
    box-shadow: transparent !important;
}

.userRegistration .addUserSubmitBtn {
    width: 185px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 60px;
}

.userRegistration .css-1wa3eu0-placeholder {
    height: 25px;
    text-align: left;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    opacity: 1;
    font-weight: normal;
}

.userRegistration .footerText {
    height: 15px;
    text-align: center;
    font: normal normal normal 11px/13px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    margin-top: 25%;
}

.newCompanyConfirmation {
    margin-top: 412px;
}

.newCompanyConfirmation .userlistlabel {
    height: 99px;
    text-align: center;
    font: normal normal bold 30px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    margin-bottom: 17px;
}

.newCompanyConfirmation .userlistSublabel {
    height: 15px;
    text-align: center;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-bottom: 30px;
}

.newCompanyConfirmation .proceedBtn {
    width: 185px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.newCompanyConfirmation .footerText {
    margin-top: 70%;
    height: 15px;
    text-align: center;
    font: normal normal normal 11px/13px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
}

.CompleteUserRegistration {
    margin-top: 458px;
    text-align: center;
}

.CompleteUserRegistration .userlistlabel {
    height: 62px;
    text-align: center;
    font: normal normal bold 53px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    margin-bottom: 8px;
}

.CompleteUserRegistration .userlistSublabel {
    height: 15px;
    text-align: center;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-bottom: 30px;
}

.CompleteUserRegistration .loginBtn {
    width: 185px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.CompleteUserRegistration .footerText {
    margin-top: 80%;
    height: 15px;
    text-align: center;
    font: normal normal normal 11px/13px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
}

.reports {
    padding: 27px 42px 27px 37px;
}

.reports .searchDiv {
    width: 100%;
    height: 59px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border-radius: 100px;
    padding-top: 9px;
    margin-bottom: 58px;
}

.reports .searchDiv i {
    padding: 12px;
    font-size: 16px;
}

.reports .searchDiv span {
    font-size: 22px;
    color: #BBC5D5;
    padding: 6px;
}

.reports .searchDiv input {
    background: transparent;
    background-color: transparent;
    border: none;
    outline: none;
    height: 35px;
}

.reports .userlistlabel {
    height: 44px;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.reports .userlistSublabel {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-bottom: 70px;
}

.reports .reportDetails .reportName {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
    float: left;
}

.reports .reportDetails .viewBtn {
    height: 22px;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 18px/22px Lato;
    letter-spacing: 0px;
    color: #322A7D;
    float: right;
}

.reports .reportDetails {
    border-bottom: 1px solid #E2E7EE;
    padding-bottom: 20px;
}

.userRegistration .css-tlfecz-indicatorContainer {
    color: #87C748 !important;
    position: relative;
    right: 35%;
}

.AddStorage {
    margin-top: 20px !important;
    margin-right: 0px !important;
}

.AddStorage .priceingDiv{
    height: 290px !important;
}