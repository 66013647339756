.LicenseDashboard .activityLogDiv .licenseCount span {
    height: 27px;
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    margin-right: 45px;
}

.LicenseDashboard .actionDiv {
    position: relative;
    border-bottom: solid 4px #FFA101;
    left: 1.5%;
    width: 84px;
    height: 4px;
}

.LicenseDashboard .companyDetails {
    padding: 6px;
    padding-top: 30px;
    width: 100% !important;
}

.LicenseDashboard .companyDetails span:first-child {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.LicenseDashboard .companyDetails span:first-child select option {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.LicenseDashboard .companyDetails span:first-child select {
    width: 435px;
    height: 49px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    margin-left: 15px;
    outline: none;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.LicenseDashboard .selectdiv, .licenseDetails .selectdiv {
    position: relative;
    float: left;
}

.LicenseDashboard select::-ms-expand, .licenseDetails select::-ms-expand {
    display: none;
}

.LicenseDashboard .selectdiv:after, .licenseDetails .selectdiv:after {
    content: url(../images/arrow-right.png);
    top: 0%;
    right: 9%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.LicenseDashboard .companyDetails .selectdiv:after {
    content: url(../images/arrow-right.png);
    top: 60%;
    right: 5%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.LicenseDashboard .selectdiv select, .licenseDetails .selectdiv select {
    appearance: none;
    max-width: 320px;
    padding: 0px 14px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    -ms-word-break: normal;
    word-break: normal;
}

.LicenseDashboard .searchField {
    float: right !important;
    width: 25%;
}

.LicenseDashboard .searchField input {
    width: 100%;
    height: 50px;
    border: none;
    border: 1px solid grey;
    font-size: 15px;
    outline: none;
    background: transparent;
    border-radius: 20px;
    padding-left: 20px;
}

.LicenseDashboard .licensesDiv {
    margin-top: 10px;
    text-align: left;
    font: normal normal normal 18px/19px Lato !important;
    letter-spacing: 0px;
    color: #354052;
    height: 677px;
    background-color: white;
    min-height: 665px;
    max-height: 677px;
    overflow-y: auto;
    margin-left: 4px;
    margin-right: 0px;
}

.LicenseDashboard .licensesDiv .viewButton {
    height: 22px !important;
    text-decoration: underline;
    font: normal normal bold 18px/22px Lato !important;
    letter-spacing: 0px;
    color: #BBC5D5;
    border: none;
    background: none;
    font-weight: 300 !important;
    text-align: left;
}

.LicenseDashboard .licensesDiv .infoDetails {
    font-size: 13px !important;
    word-break: break-word;
}

.LicenseDashboard .licensesDiv .licenceKeyDiv {
    background: #F7F8FA 0% 0% no-repeat padding-box;
    font: normal normal normal 14px/17px Lato !important;
    border-radius: 100px;
    width: 100%;
    padding: 3% 3% 3% 3%;
}

.LicenseDashboard .licensesDiv .copyImg img {
    width: 18px;
    height: 18px;
    background: transparent
}

.LicenseDashboard .licensesDiv .key {
    float: left;
    width: 63%;
}

.LicenseDashboard .licensesDiv .green {
    background: #87c748;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    position: relative;
    top: 1px;
    margin-left: 20px !important;
    display: inline-block;
}

.LicenseDashboard .licensesDiv .red {
    background: red;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    position: relative;
    top: 1px;
    margin-left: 20px !important;
    display: inline-block;
}

.LicenseDashboard .licensesDiv .copyImg, .LicenseDashboard .licensesDiv .keySeperator {
    float: right;
    cursor: pointer;
}

.LicenseDashboard .licensesDiv .keySeperator {
    padding-right: 10px;
    height: 18px;
    color: #BBC5D5;
}
.LicenseDashboard .sc-crzoAE{
    padding: 35px 0px;
}
.LicenseDashboard .dSYVyP {
    /* height: 19px; */
    text-align: center;
    font: normal normal normal 16px/19px Lato !important;
    letter-spacing: 0px;
    color: #7F8FA4 !important;
    margin-bottom: 40px;
    margin-top: 40px;
    font-weight: 500 !important;
}

.LicenseDashboard .cchvzS {
    text-align: left;
    font: normal normal normal 18px/19px Lato !important;
    letter-spacing: 0px;
    color: #354052 !important;
    height: 22px;
}

.LicenseDashboard .cDmETx {
    display: none;
}

.LicenseDashboard .ecDzaP {
    padding-left: 40px;
    padding-right: 40px;
}

.LicenseDashboard .rdt_TableBody {
    min-height: 500px;
    height: 500px;
}

.LicenseDashboard input[type='radio']:after {
    width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.LicenseDashboard input[type='radio']:checked:after {
    width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #87c748;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.licenseDetails {
    padding-top: 110px !important;
    height: 100%;
    padding: 40px;
}

.licenseDetails .loginTitle {
    height: 44px;
    width: 100%;
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    text-transform: none;
}

.licenseDetails .addExpSubTitle {
    height: 20px;
    width: 100%;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.licenseDetails .licenseKeyDetails .keyIcon img {
    width: 64px;
    height: 64px;
}

.licenseDetails .licenseKeyDetails .keyDetails {
    height: 46px;
    width: 315px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070 !important;
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
    padding: 13px;
    cursor: pointer;
}

.licenseDetails .licenseKeyDetails .keyDetails .key {
    padding: 13px !important;
    display: inline-block;
    padding-right: 80px !important;
}

.licenseDetails .licenseKeyDetails .keyDetails .copyImg {
    width: 18px;
    height: 18px;
}

.licenseDetails .keyInfo {
    margin-top: 47px;
}

.licenseDetails .keyInfo .keyname {
    height: auto;
    float: left;
    font: normal normal normal 18px/24px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
    margin-bottom: 2%;
}

.licenseDetails .keyInfo .keyvalue {
    height: auto;
    float: left;
    font: normal normal normal 15px/24px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.licenseDetails .assignNewDetails {
    height: 300px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 40px;
}

.licenseDetails .assignNewDetails select {
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 2px solid #E9E9F0;
    height: 21px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    outline: none;
}

.licenseDetails .assignNewDetails .saveChanges {
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    width: 100%;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.licenseDetails .resellerUnassignRenewBtns {
    margin-top: 18px;
}

.licenseDetails .resellerUnassignRenewBtns .unassign {
    height: 59px;
    width: 100%;
    border: none;
    background: #FCCACF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    text-align: center;
    font: normal normal 900 16px/22px Lato;
    letter-spacing: 0px;
    color: #FF0000;
}

.licenseDetails .resellerUnassignRenewBtns .renew {
    height: 59px;
    border: none;
    width: 100%;
    background: #DBEEC8 0% 0% no-repeat padding-box;
    border-radius: 6px;
    text-align: center;
    font: normal normal 900 16px/22px Lato;
    letter-spacing: 0px;
    color: #87C749;
}

.licenseDetails .licenseRenewalDetails {
    height: 680px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin-top: 50px;
    padding: 30px;
}

.licenseDetails .licenseSubscription {
    border-bottom: 2px solid #E9E9F0;
    padding-bottom: 1%;
    margin-top: 10px;
    margin-bottom: 50px;
}

.licenseDetails .licenseSubscription .subscriptionPreiodText {
    height: 19px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    padding-right: 5%;
}

.licenseDetails .licenseSubscription .subscriptionPreiodSeperator {
    height: 19px;
}

.licenseDetails .licenseSubscription .subscriptionPreiodSelect {
    height: 19px;
    text-align: left;
    font: normal normal normal 15px/35px Source Sans Pro;
    letter-spacing: 0px;
    color: #87C748;
    padding-left: 5%;
}

.licenseDetails .pricingTable thead {
    border-bottom: 3px solid #11141A;
}

.licenseDetails .pricingTable thead tr th {
    height: 19px;
    text-align: left;
    font: normal normal bold 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #11141A;
    border-top: none !important;
}

.licenseDetails .pricingTable tbody tr * {
    height: 19px;
    text-align: left;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
    padding: 35px 0px;
}

.licenseDetails .pricingTable tbody .totalLabel {
    height: 19px;
    text-align: left;
    font: normal normal bold 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
}

.licenseDetails .pricingTable tbody .totalPrice {
    height: 19px;
    text-align: left;
    font: normal normal bold 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
}

.proceedBtn {
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/23px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 100%;
}

.LicenseDashboard .rdt_TableHeader {
    display: none;
}

.buyLicenses {
    margin-top: 140px;
    margin-right: 40px;
}

.buyLicenses .loginTitle {
    width: 100%;
    /* height: 44px; */
    text-align: left;
    font: normal normal bold 37px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.buyLicenses input[type='radio']:after {
    width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -8px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.buyLicenses input[type='radio']:checked:after {
    width: 17px;
    height: 17px;
    border-radius: 15px;
    top: -8px;
    left: -1px;
    position: relative;
    background-color: #87c748;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.buyLicenses .form-check-label {
    font-size: 13px;
    position: relative;
}

.buyLicenses .form-check {
    position: relative;
    top: 7px;
}

.buyLicenses .companyDetails span:first-child select option {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.buyLicenses .companyDetails span:first-child select {
    width: 100%;
    height: 49px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    margin-left: 6px;
    outline: none;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
    word-break: break-word;
}

.buyLicenses .selectdiv {
    position: relative;
    /* float: left; */
    /* width: 100%; */
}

.buyLicenses select::-ms-expand {
    display: none;
}

.buyLicenses .selectdiv:after {
    content: url(../images/arrow-right.png);
    top: 0%;
    right: 5%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.buyLicenses .priceingDiv .selectdiv:after {
    content: url(../images/arrow-right.png);
    top: 27%;
    right: 8%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.buyLicenses .selectdiv select {
    -webkit-appearance: none;
    appearance: none;
    max-width: 100%;
    width: 100%;
    padding: 0px 24px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    -ms-word-break: normal;
    word-break: normal;
    border: none;
    background: transparent;
    /* border-bottom: 1px solid lightgrey; */
}

.buyLicenses .priceingDiv {
    height: 450px;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin: 32px 0px 20px 0px;
}

.buyLicenses table thead tr th {
    height: 21px;
    text-align: center;
    font: normal normal bold 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
}

.buyLicenses .firstCol {
    width: 40% !important;
}

.buyLicenses table tbody tr td {
    height: 21px;
    font: normal normal normal 15px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #43425D;
}

.buyLicenses table tbody tr td input {
    width: 40%;
    background: none;
    border: none;
    text-align: center;
}

.buyLicenses img {
    width: 23px;
    margin: 4px;
}

.buyLicenses .buyLicenseBtn {
    width: 399px;
    height: 50px;
    background: #43425D 0% 0% no-repeat padding-box;
    border: 1px solid #43425D;
    border-radius: 4px;
    text-align: center;
    font: normal normal normal 18px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.licenseDetails .selectdiv {
    position: relative;
    float: left;
}

.licenseDetails select::-ms-expand {
    display: none;
}

/* .licenseDetails .selectdiv:after {
    content: '>';
    width: 13px;
    height: 8px;
    color: #87C748;
    transform: rotate( 90deg);
    top: 20% !important;
    right: 5%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
} */

.licenseDetails .selectdiv select {
    max-width: 320px;
    padding: 0px 14px;
    color: #333;
    height: 30px !important;
    -ms-word-break: normal;
    word-break: normal;
}

.licenseDetails .licenseRenewalDetails .selectdiv {
    position: relative;
    float: none !important;
}

.licenseDetails .licenseRenewalDetails select::-ms-expand {
    display: none;
}

.licenseDetails .licenseRenewalDetails .selectdiv:after {
    content: url(../images/arrow-right.png);
    top: -30%;
    right: 9%;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    font-weight: bold;
}

.licenseDetails .licenseRenewalDetails .selectdiv select {
    width: 50%;
    padding: 0px 14px;
    color: #333;
    height: 30px !important;
    -ms-word-break: normal;
    word-break: normal;
    border: none;
    background: transparent;
    outline: none !important;
}