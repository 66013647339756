.leftSidePanel {
    position: fixed;
    width: 70px;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
    top: 0px;
}

.logoutButton {
    position: fixed;
    bottom: 4%;
    left: 1.3%;
    padding: 0px !important;
    margin: 0px !important;
    z-index: 9;
}

.logoutButton i {
    color: lightgrey;
    cursor: pointer;
}

.navBar {
    width: 100%;
    max-height: 100vh;
}

.navIcons ul {
    position: fixed;
    z-index: 1;
    top: 3%;
    bottom: auto;
    left: -0.4%;
    margin: auto;
    height: 100%;
    padding: 16%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100px;
    padding-left: 0px;
    padding-right: 0px;
}

.navIcons ul li {
    display: block;
    width: 75%;
}

.navIcons {
    height: 100%;
    max-height: 100vh;
}

.navIcons img {
    width: 70%;
    color: lightgrey;
    padding: 20%;
    font-size: 18px;
    cursor: pointer;
}

.navIcons i {
    width: 100%;
    color: lightgrey;
    padding: 20%;
    font-size: 18px;
    cursor: pointer;
}

.navIcons .active {
    color: #322A7D !important;
    border-right: 4px solid #87C748 !important;
    width: 75% !important;
    position: relative;
    left: 3%;
}

.logoImg {
    transform: scale(0.2);
    position: absolute;
    left: -129%;
    top: -4%;
}

.navIconImg, .logoutButton {
    width: 18px;
    height: 18px;
    cursor: pointer;
}