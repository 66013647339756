.searchDiv {
    background-color: #F7F8FA;
}

.userDetails {
    margin: 17px 32px 30px 30px;
}

.userDetails .searchDiv {
    width: 333px;
    height: 59px;
    background: #F7F8FA 0% 0% no-repeat padding-box;
    border-radius: 100px;
    padding-top: 9px;
}

.userDetails .searchDiv i {
    padding: 12px;
    font-size: 16px;
}

.userDetails .searchDiv span {
    font-size: 22px;
    color: #BBC5D5;
    padding: 6px;
}

.userDetails .searchDiv input {
    background: transparent;
    background-color: transparent;
    border: none;
    outline: none;
    height: 35px;
}

.notificationBox {
    width: 59px;
    height: 59px;
    background-color: #F7F8FA;
    border-radius: 40px;
    padding: 15px 13px;
    text-align: center;
}

.notificationBox img {
    width: 20px;
    height: 24px;
}

.userIcon {
    width: 102px;
    height: 112px;
}

.userInformation .greetings {
    height: 34px;
    text-align: left;
    font: normal normal normal 29px/32px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.userInformation .userName {
    height: 25px;
    text-align: left;
    font: normal normal bold 23px/26px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    text-transform: capitalize;
}

.userInformation .userRole {
    height: 23px;
    text-align: left;
    font: normal normal normal 12px/18px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.companyDetails {
    padding: 6px;
    width: 100% !important;
}

.companyDetails p:first-child {
    height: 15px;
    text-align: center;
    font: normal normal normal 12px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.userDetails .companyDetails p:nth-child(2) {
    height: 22px;
    text-align: center;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.userDetails .editCompany {
    width: 100%;
    height: 59px;
    background: #BBC5D5 0% 0% no-repeat padding-box;
    border-radius: 100px;
    text-align: center;
    font: normal normal 900 15px/21px Lato;
    letter-spacing: 0px;
    color: #322A7D;
    opacity: 1;
}

.companyDetails p {
    line-height: 1.7 !important;
}

.userDetails .settingsSection {
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
    margin: auto;
}

.settingsSection .settingsDescription {
    padding-left: 50px;
    padding-top: 50px;
}

.settingsSection .settingsTitle {
    height: 20px;
    text-align: left;
    font: normal normal 800 26px/30px Raleway;
    letter-spacing: 0px;
    color: #FFFFFF;
    line-height: 3px;
}

.settingsSection .settingsSubTitle {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.settingsSection .settingsIcon {
    width: 80px;
    height: 80px;
}

.helpSection .helpText {
    height: 27px;
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.helpSection .viewAll {
    height: 19px;
    text-align: right;
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0px;
    color: #87C748;
}

.helpSection .helpIcons {
    width: 64px;
    height: 64px;
}

.helpSection .helpTitle {
    height: 21px;
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
}

.helpSection .helpSubTitle {
    height: 20px;
    text-align: left;
    font: normal normal normal 12px/14px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.userDetails .footerDivHR {
    position: absolute;
    bottom: 5%;
    left: 0%;
    border-top: 1px solid #707070;
    width: 100%;
}

.userDetails .footerDiv {
    position: absolute;
    left: 25%;
    bottom: 11px !important;
}

.helpSection .footerSection {
    height: 15px;
    text-align: center;
    font: normal normal normal 12px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.notificationPanel img {
    width: 24px;
    height: 24px;
    float: left;
}

.notificationPanel .notificationTitle {
    text-align: left;
    font: normal normal bold 29px/18px Raleway;
    letter-spacing: 0px;
    color: #11141A;
}

.notificationPanel .closeBtn {
    float: right;
    text-align: left;
    font: normal normal bold 29px/18px Gill Sans;
    letter-spacing: 0px;
    color: #ACADAF;
    cursor: pointer;
}

.userDetails .clearBtn {
    text-align: left;
    font: normal normal bold 16px/19px Lato;
    letter-spacing: 0px;
    color: #87C748;
    cursor: pointer;
    margin: auto;
}

.userDetails .withNotification {
    cursor: pointer;
}