.leftSidePanel * {
  user-select: none;
}

.App {
  text-align: left;
}

.Mobile {
  position: relative;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  word-break: break-word;
  background-color: black;
  color: white;
}

.hide {
  display: none !important;
}

.submitButtonSection {
  position: relative;
  z-index: 99;
}

/* Main Content Container Css*/

.middleContent {
  background-color: #F7F8FA;
  padding-left: 90px;
  /* height: 100vh; */
  min-height: 100vh;
}

/* End Main Content Container Css*/

/* Header Css  */

/*  Buy Licenses Button Header */

.headerButton {
  width: 200px;
  height: 59px;
  background: #322A7D 0% 0% no-repeat padding-box;
  border-radius: 6px;
  /* text-align: left; */
  font: normal normal 900 16px/22px Lato;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-top: 27px;
}

.headerTitle {
  color: #11141A;
  padding-top: 27px;
  font-weight: bold;
  text-align: left;
  font: normal normal bold 53px/32px Raleway;
  letter-spacing: 0px;
  height: 62px;
}

.headerSubTitle {
  text-align: left;
  font: normal normal normal 12px/14px Lato;
  letter-spacing: 0px;
  color: #7F8FA4;
  height: 20px;
}

/* End Header Css  */

/* BackDrop CSS */

.backdropDiv {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  opacity: 0.9;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Disable Links  */

.disabled-link {
  pointer-events: none;
}

.margin-auto {
  margin: auto !important;
}

.loader {
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 20;
  background: lightgray;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  left: 0px;
  right: 0px;
}

.loader svg {
  top: 42%;
  bottom: 50%;
  left: 46%;
  right: 50%;
  position: absolute;
}

#previewImage {
  width: 370px;
  height: 600px;
  position: relative;
  top: 5%;
}

.addExperience .closeSideTab {
  float: right;
  font-weight: 600;
  font-size: 24px;
  color: #93c95b;
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 8%;
}

.sc-crzoAE {
  text-align: center;
  font: normal normal normal 16px/19px Lato !important;
  letter-spacing: 0px;
  color: #7F8FA4 !important;
  font-weight: 500 !important;
}

.rdt_Pagination {
  border: none !important;
}

.backIcon {
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 6px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: darkgray;
}