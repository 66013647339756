.mainDashboard .quickStats {
    /* height: 146px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 12px;
    width: 100%;
    padding: 10% 0% 0% 10%; */
    max-height: 146px;
    min-height: 146px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 12px;
    width: 100%;
    padding: 10% 0% 0% 10%;
    overflow-y: auto;
}

.mainDashboard .QuickStatsTitle {
    text-align: left;
    font: normal normal bold 18px/21px Raleway;
    letter-spacing: 0px;
    color: #354052;
    text-transform: capitalize;
}

.mainDashboard .quickStats .title {
    width: 100%;
    height: auto;
    min-height: 34px;
    text-align: left;
    font: normal normal normal 18px/22px Lato;
    letter-spacing: 0px;
    color: #354052;
}

.mainDashboard .quickStats .fileStorageIcon {
    width: 40%;
}

.mainDashboard .quickStats .LicensesValue {
    height: 48px;
    text-align: left;
    font: normal normal 900 40px/48px Lato;
    letter-spacing: 0px;
    color: #11141A;
}

.mainDashboard .quickStats .DevicesValue {
    height: 48px;
    text-align: left;
    font: normal normal 900 40px/48px Lato;
    letter-spacing: 0px;
    color: #87C748;
}

.mainDashboard .quickStats .ExperiencesValue {
    height: 48px;
    text-align: left;
    font: normal normal 900 40px/48px Lato;
    letter-spacing: 0px;
    color: #11141A;
}

.mainDashboard .quickStats .FileStorageValue {
    height: 48px;
    text-align: left;
    font: normal normal 900 28px/48px Lato;
    /* font: normal normal 900 40px/48px Lato; */
    letter-spacing: 0px;
    color: #11141A;
}

.mainDashboard .ActivityLogTitle {
    text-align: left;
    font: normal normal bold 23px/27px Raleway;
    letter-spacing: 0px;
    color: #11141A;
    width: 100%;
}

.mainDashboard .actionDiv {
    position: relative;
    border-bottom: solid 4px #87C748;
    left: 1.5%;
    width: 84px;
    height: 4px;
}

.mainDashboard .activityLogDiv {
    width: 84px;
    position: relative;
    height: 70px;
}

.activitiesDiv {
    background-color: white;
    height: 560px;
    overflow-y: auto;
}

.activitiesDiv .activityTitle {
    width: 32px;
    height: 18px;
    text-align: left;
    font: normal normal normal 15px/18px Lato;
    letter-spacing: 0px;
    color: #7F8FA4;
}

.activitiesDiv .activityContent {
    height: 22px;
    text-align: left;
    font: normal normal normal 18px/19px Lato;
    letter-spacing: 0px;
    color: #354052;
    padding-top: 7px;
}

.activitiesDiv .activityRow {
    border-bottom: 1px solid #F0F0F0;
    padding: 10px;
}